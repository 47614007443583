import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios, { CancelToken } from "axios";
// import { v4 as uuidv4 } from "uuid";
import { Box, useMediaQuery, Typography, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import background from "../imges/background.png";
import AppStore from "../components/StyledComponents/AppStore";
import Paper from "@mui/material/Paper";
import FlexCenter from "../components/StyledComponents/FlexCenter";
import { useTheme } from "@mui/material/styles";
import FlexBetween from "../components/StyledComponents/FlexBetween";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddFileButton from "../components/FileUploadingPageComponents/AddFileButton";
import CompressingComponents from "../components/FileUploadingPageComponents/CompressingComponents";
import ComprssedComponents from "../components/FileUploadingPageComponents/ComprssedComponents";
import FileAddingComponents from "../components/MoblieResponsiveFileUploadingPage/FileAddingComponents";
import MobileCompressingComponents from "../components/MoblieResponsiveFileUploadingPage/MobileCompressingComponents";
import MobileCompressedComponents from "../components/MoblieResponsiveFileUploadingPage/MobileCompressedComponents";
import VideoIcon from "../components/FileUploadingPageComponents/VideoIcon";
import PromotionalComponent from "../components/FileUploadingPageComponents/PromotionalComponent";
import AppStoreWeb from "../components/StyledComponents/AppStoreWeb";
// import { onMessage } from "firebase/messaging";
// import { messaging } from "../firebase";
// import DisplayNotification from "../helper/notificationHelper";

const Item = (props) => (
  <Paper
    sx={{
      justifyContent: "flex-start",
      padding: "16px 15%",
      borderRadius: "0px",
      ...props.sx,
    }}
  >
    {props.children}
  </Paper>
);

const styles = {
  root: {
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // padding: "0px 4% 3% 4%",
    // pt:"50rem"
    margin:"4rem 0px 5% 0px"
  },
  text: {
    color: "white",
    textAlign: "center",
  },
};

function FileUploadingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobileScreen = useMediaQuery("(min-width:1000px)");
  // const [socket, setSocket] = useState(null);
  const [multipleFilesSelected, setMultipleFilesSelected] = useState([]);
  const [isFileCompressing, setIsFileCompressing] = useState(false);
  const [isFileAdding, setIsFileAdding] = useState(false);
  const [isFileCompressed, setIsFileCompressed] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const [fileUploadingProgress, setFileUploadingProgress] = useState({});
  const [compressedResult, setCompressedResult] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [cancelTokenSources, setCancelTokenSources] = useState([]);
  // const [notification, setNotification] = useState({ title: '', body: '' });
  const [isFileSizeMBExceed, setIsFileSizeMBExceed] = useState(false);

  const handelMultipleFilesSelected = (file) => {
    setMultipleFilesSelected((prevFiles) => [...prevFiles, file]);
  };

  const onFilesSelected = async (selectedFile) => {
    handelMultipleFilesSelected(selectedFile);
  };

  const handleRemoveFile = (index) => {
    // Find the cancellation token source for the file at the specified index
    const cancelTokenSource = cancelTokenSources[index];

    // Cancel the corresponding Axios request
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled by user");
    }

    setMultipleFilesSelected((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);

      // Check if the updated array is empty
      if (newFiles.length === 0) {
        // Wrap the navigation logic in setTimeout to delay it
        setTimeout(() => {
          navigate("/videocompressor");
        }, 0);
      }

      return newFiles;
    });
  };

  // const handleUploadAndCompression = async (files) => {
  //   setIsFileAdding(false);
  //   setIsFileCompressing(true);

  //   try {
  //     const fcmToken = localStorage.getItem("fcmToken"); // Retrieve the FCM token from local storage
  //     const promises = files.map(async (file, index) => {
  //       const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB
  //       console.log(`fileSizeMB:${fileSizeMB}`);

  //       if (fileSizeMB <= 50) {
  //         const source = CancelToken.source();
  //         setCancelTokenSources((prevSources) => [...prevSources, source]);

  //         const formData = new FormData();
  //         formData.append("file", file);
  //         formData.append("fcmToken", fcmToken);

  //         try {
  //           const response = await axios.post(
  //             `${process.env.REACT_APP_BASE_URL}/uploadsandcompress`,
  //             formData,
  //             {
  //               onUploadProgress: (progressEvent) => {
  //                 const progressPercent =
  //                   (progressEvent.loaded / progressEvent.total) * 100;
  //                 setFileUploadingProgress((prevProgress) => ({
  //                   ...prevProgress,
  //                   [file.name]: progressPercent,
  //                 }));
  //               },
  //               cancelToken: source.token,
  //             }
  //           );

  //           if (response.status === 200) {
  //             const contentType = response.headers["content-type"];
  //             if (contentType && contentType.includes("application/json")) {
  //               const result = response;
  //               const completedFile = multipleFilesSelected.find(
  //                 (uploadingFile) =>
  //                   uploadingFile.name === result.data.file.filename
  //               );
  //               setMultipleFilesSelected((prevFiles) =>
  //                 prevFiles.filter(
  //                   (uploadingFile) =>
  //                     uploadingFile.name !== result.data.file.filename
  //                 )
  //               );
  //               if (completedFile) {
  //                 setUploadedFiles((prevFiles) => [
  //                   ...prevFiles,
  //                   completedFile,
  //                 ]);
  //               }
  //               // console.log(`result-${JSON.stringify(result.data.file)}`);
  //               setCompressedResult((prevFiles) => {
  //                 const originalFileSize = isNaN(
  //                   result.data.file.originalFileSize
  //                 )
  //                   ? (file.size / (1024 * 1024)).toFixed(2)
  //                   : result.data.file.originalFileSize;
  //                 return [
  //                   ...prevFiles,
  //                   {
  //                     file: {
  //                       ...result.data.file,
  //                       originalFileSize: originalFileSize,
  //                       isFileSizeExceed: false,
  //                     },
  //                   },
  //                 ];
  //               });
  //             } else {
  //               console.error("Invalid Content-Type:", contentType);
  //             }
  //           }
  //         } catch (error) {
  //           if (axios.isCancel(error)) {
  //             setIsFileCompressing(true);
  //             setIsFileCompressed(false);
  //             console.log("Request canceled:", error.message);
  //           } else {
  //             setIsFileCompressing(true);
  //             setIsFileCompressed(false);
  //             console.error("Error during upload and compression:", error);
  //           }
  //         }
  //       } else {
  //         setCompressedResult((prevFiles) => {
  //           const originalFileSize = fileSizeMB.toFixed(2);
  //           return [
  //             ...prevFiles,
  //             {
  //               file: {
  //                 filename: file.name,
  //                 originalFileSize: originalFileSize,
  //                 isFileSizeExceed: true,
  //               },
  //             },
  //           ];
  //         });
  //       }
  //     });

  //     const allResponse = await Promise.all(promises);
  //     if (allResponse) {
  //       setIsFileCompressing(false);
  //       setIsFileCompressed(true);
  //     } else {
  //       setIsFileCompressing(true);
  //       setIsFileCompressed(false);
  //       console.log(`error in all response`);
  //     }
  //   } catch (error) {
  //     console.error("Error during upload and compression:", error);
  //   }
  // };

  const handleUploadAndCompression = async (files) => {
    setIsFileAdding(false);
    setIsFileCompressing(true);
  
    try {
      const fcmToken = localStorage.getItem("fcmToken"); // Retrieve the FCM token from local storage
      let totalFileSizeMB = 0;
  
      // Calculate the total size of selected files
      files.forEach((file) => {
        totalFileSizeMB += file.size / (1024 * 1024); // Convert file size to MB and sum it
      });
  
      const fileSizeMBExceed = totalFileSizeMB > 50; // Check if the total size exceeds the limit
  
      if (fileSizeMBExceed) {
        setCompressedResult((prevFiles) => [
          ...prevFiles,
          ...files.map((file) => ({
            file: {
              filename: file.name,
              originalFileSize: (file.size / (1024 * 1024)).toFixed(2),
              isFileSizeExceed: true,
            },
          })),
        ]);
        setIsFileCompressing(false);
        setIsFileCompressed(false);
        setIsFileSizeMBExceed(true);
        // console.log("Total file size exceeds the 50 MB limit.");
        return; // Stop further processing
      }
  
      // Proceed with API calls if total size is within the limit
      const promises = files.map(async (file) => {
        const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB
        // console.log(`fileSizeMB:${fileSizeMB}`);
  
        const source = CancelToken.source();
        setCancelTokenSources((prevSources) => [...prevSources, source]);
  
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fcmToken", fcmToken);
  
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/uploadsandcompress`,
            formData,
            {
              onUploadProgress: (progressEvent) => {
                const progressPercent =
                  (progressEvent.loaded / progressEvent.total) * 100;
                setFileUploadingProgress((prevProgress) => ({
                  ...prevProgress,
                  [file.name]: progressPercent,
                }));
              },
              cancelToken: source.token,
            }
          );

          if (response.status === 200) {
            const contentType = response.headers["content-type"];
            if (contentType && contentType.includes("application/json")) {
              const result = response;
              const completedFile = multipleFilesSelected.find(
                (uploadingFile) =>
                  uploadingFile.name === result.data.file.filename
              );
              setMultipleFilesSelected((prevFiles) =>
                prevFiles.filter(
                  (uploadingFile) =>
                    uploadingFile.name !== result.data.file.filename
                )
              );
              if (completedFile) {
                setUploadedFiles((prevFiles) => [...prevFiles, completedFile]);
              }
              setCompressedResult((prevFiles) => {
                const originalFileSize = isNaN(
                  result.data.file.originalFileSize
                )
                  ? (file.size / (1024 * 1024)).toFixed(2)
                  : result.data.file.originalFileSize;
                return [
                  ...prevFiles,
                  {
                    file: {
                      ...result.data.file,
                      originalFileSize: originalFileSize,
                      isFileSizeExceed: false,
                    },
                  },
                ];
              });
            } else {
              console.error("Invalid Content-Type:", contentType);
            }
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            setIsFileCompressing(true);
            setIsFileCompressed(false);
            setIsFileSizeMBExceed(false);
            console.log("Request canceled:", error.message);
          } else {
            setIsFileCompressing(true);
            setIsFileCompressed(false);
            setIsFileSizeMBExceed(false);
            console.error("Error during upload and compression:", error);
          }
        }
      });
  
      const allResponse = await Promise.all(promises);
      if (allResponse) {
        setIsFileCompressing(false);
        setIsFileCompressed(true);
        setIsFileSizeMBExceed(false);
      } else {
        setIsFileCompressing(true);
        setIsFileCompressed(false);
        setIsFileSizeMBExceed(false);
        console.log(`Error in all response`);
      }
    } catch (error) {
      console.error("Error during upload and compression:", error);
    }
  };
  

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_BASE_URL}`);
    socket.on("compression-progress", ({ filename, progress }) => {
      setFileProgress((prevProgress) => {
        // Using the functional form to ensure the latest state is used
        return {
          ...prevProgress,
          [filename]: progress,
        };
      });
    });

    socket.on("compression-completed", ({ filename }) => {
      // console.log(`filename:${filename}`);
      // setCompressedFile(filename);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    // Check if location is defined
    if (location && location.state) {
      // Check if location.state is defined and contains the necessary data
      // console.log(location.state);
      const { selectedFile } = location.state;
      // console.log(selectedFile);
      handelMultipleFilesSelected(selectedFile);
      setIsFileAdding(true);
    } else {
      // Handle the case where location is not defined
      console.error("Location is not defined");
    }
  }, [location]);

  return (
    <>
      <Box style={styles.root}>
        <Header />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            // border: "1px solid red",
            mt: "4rem",
            // backgroundColor:"#C582F5"
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(113, 87, 186, 0.65)",
          }}
        >
          <AppStore />
        </Box>
        <FlexCenter sx={{disply:"flex",justifyContent:"center" ,alignItems:"center"}}>
          <Typography
            sx={{
              color: "black",
              fontSize: isNonMobileScreen ? "2.687rem" : "24px",
              fontWeight: "600",
              wordWrap: "break-word",
              mt:"1rem",
              textAlign:"center"
            }}
            variant={theme.typography.h2}
          >
            {isFileAdding && "Files "}
            {isFileCompressing && "Compression is "}
            {isFileCompressed && "Compressed "}
            {isFileSizeMBExceed && "Unable To Compress,"}
            {/* Files{" "} */}
            <span
              style={{
                background:
                  "linear-gradient(to right, rgba(88, 14, 228, 1), rgba(224, 102, 251, 1))",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              {isFileAdding && "Added Successfully "}
              {isFileCompressing && "Processing"}
              {isFileCompressed && "video Successfully"}
              {isFileSizeMBExceed && "Please Try Offline"}
            </span>
          </Typography>
        </FlexCenter>
        {uploadedFiles.length > 1 && isNonMobileScreen && (
          <>
            <FlexCenter>
              <Typography
                sx={{
                  color: "#727CF5",
                  fontSize: isNonMobileScreen ? "24px" : "12px",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
                variant={theme.typography.h2}
              >
                Total Files:
                <span
                  style={{
                    color: "black",
                  }}
                >
                  {uploadedFiles.length}
                </span>
              </Typography>
            </FlexCenter>
          </>
        )}

        <Box mt="16px">
          {!isNonMobileScreen && isFileAdding && (
            <FileAddingComponents
              multipleFilesSelected={multipleFilesSelected}
              handleRemoveFile={handleRemoveFile}
              handleUploadAndCompression={handleUploadAndCompression}
              onFilesSelected={onFilesSelected}
            />
          )}
          {isNonMobileScreen && isFileAdding && (
            <>
              <FlexCenter>
                <Box
                  sx={{
                    width: "75%",
                    background: "white",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
                    borderRadius: "5px",
                    flexWrap: "nowrap",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "3rem 0px",
                  }}
                >
                  <Box
                    sx={{
                      width: "80%",
                      background: "white",
                      boxShadow:
                        "0px 2px 15.100000381469727px 2px rgba(0, 0, 0, 0.10)",
                      borderRadius: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      padding: "1rem 0px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "90%",
                        // minHeight: isNonMobileScreen ? "60%" : "85%",
                        borderTopLeftRadius: "33px",
                        borderTopRightRadius: "33px",
                        border: "1px rgba(0, 0, 0, 0.25) solid",
                        // position: "relative",
                      }}
                    >
                      <Grid
                        container
                        // direction="row"
                        // justifyContent="flex-start"
                        // alignItems="flex-end"
                        width="100%"
                        height="100%"
                        // position="absolute"
                        box-sizing="border-box"
                      >
                        <Grid item lg={12} border="none">
                          <Item
                            sx={{
                              borderTopLeftRadius: "33px",
                              borderTopRightRadius: "33px",
                              border: "none",
                              // background: "rgba(114, 124, 245, 0.10)",
                              color: "rgba(0, 0, 0, 0.50)",
                              boxShadow: "none",
                              // width:"200px",
                              padding: "0px 0px 0px 0px",
                            }}
                          >
                            <AddFileButton onFilesSelected={onFilesSelected} />
                          </Item>
                        </Grid>
                        {multipleFilesSelected.length > 0 &&
                          multipleFilesSelected.map((file, index) => (
                            <React.Fragment key={index}>
                              <Grid item lg={6} border="none">
                                <Item
                                  sx={{
                                    borderTopLeftRadius: "33px",
                                    border: "none",
                                    color: "rgba(0, 0, 0, 0.50)",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Box sx={{ position: "relative" }}>
                                    <VideoIcon left={"-45px"} />
                                    <Typography
                                      sx={{
                                        color: "black",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        wordWrap: "break-word",
                                        ml: 1,
                                      }}
                                      variant={theme.typography.h5}
                                    >
                                      {file.name}
                                    </Typography>
                                  </Box>
                                </Item>
                              </Grid>

                              <Grid item lg={2} border="none">
                                <Item
                                  sx={{
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "black",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      wordWrap: "break-word",
                                    }}
                                    variant={theme.typography.h5}
                                  >
                                    {/* (data.compressedFileSize / (1024 * 1024)).toFixed(2) */}
                                    {(file.size / (1024 * 1024)).toFixed(2)}MB
                                  </Typography>
                                </Item>
                              </Grid>

                              <Grid item lg={3} border="none">
                                <Item
                                  sx={{
                                    color: "rgba(0, 0, 0, 0.50)",
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                >
                                  <Box>
                                    <FlexBetween>
                                      <Typography
                                        sx={{
                                          color: "black",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                          wordWrap: "break-word",
                                        }}
                                        variant={theme.typography.h5}
                                      >
                                        Output:
                                      </Typography>
                                      <Box
                                        textAlign="center"
                                        border="1px rgba(0, 0, 0, 0.50) solid"
                                        borderRadius="5px"
                                        color="blue"
                                        p="2px 2px"
                                      >
                                        <Typography>MP4</Typography>
                                      </Box>
                                    </FlexBetween>
                                  </Box>
                                </Item>
                              </Grid>

                              <Grid item lg={1} border="none">
                                <Item
                                  sx={{
                                    borderTopRightRadius: "33px",
                                    color: "rgba(0, 0, 0, 0.50)",
                                    border: "none",
                                    boxShadow: "none",
                                  }}
                                  // onClick={handleRemoveFile(index)}
                                  // onClick={() => removeFile(index)}
                                >
                                  <Box>
                                    <CancelRoundedIcon
                                      sx={{
                                        fontSize: "26px",
                                        transition:
                                          "transform 0.3s ease-in-out",
                                        "&:hover": {
                                          cursor: "pointer",
                                          color: "red",
                                          transform: "scale(1.2)",
                                        },
                                      }}
                                      onClick={() => handleRemoveFile(index)}
                                    />
                                  </Box>
                                </Item>
                              </Grid>
                            </React.Fragment>
                          ))}
                        <Grid item lg={8} border="none">
                          <Item
                            sx={{
                              background: "rgba(114, 124, 245, 0.10)",
                              color: "rgba(0, 0, 0, 0.50)",
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            Added {multipleFilesSelected.length} Files
                          </Item>
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          border="none"
                          // onClick={handleUploadAndCompression(multipleFilesSelected)}
                          onClick={() =>
                            handleUploadAndCompression(multipleFilesSelected)
                          }
                          sx={{
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Item
                            sx={{
                              background: "#727cf5",
                              border: "none",
                              boxShadow: "none",
                            }}
                          >
                            <Typography
                              variant={theme.typography.h6}
                              sx={{
                                wordWrap: "break-word",
                                fontWeight: "400",
                                color: "white",
                              }}
                            >
                              Compress Now
                            </Typography>
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </FlexCenter>
            </>
          )}
          {!isNonMobileScreen && isFileCompressing && (
            <MobileCompressingComponents
              multipleFilesSelected={multipleFilesSelected}
              fileUploadingProgress={fileUploadingProgress}
              fileProgress={fileProgress}
              uploadedFiles={uploadedFiles}
              handleRemoveFile={handleRemoveFile}
            />
          )}

          {isNonMobileScreen && isFileCompressing && (
            <CompressingComponents
              multipleFilesSelected={multipleFilesSelected}
              fileUploadingProgress={fileUploadingProgress}
              fileProgress={fileProgress}
              uploadedFiles={uploadedFiles}
              handleRemoveFile={handleRemoveFile}
            />
          )}
          {/* video file size exceeds 50mb */}
             {isFileSizeMBExceed && (<PromotionalComponent />)}
           {/* end video file size exceeds 50mb */}
          {!isNonMobileScreen && isFileCompressed && (
            <MobileCompressedComponents compressedResult={compressedResult} />
          )}

          {isNonMobileScreen &&
            isFileCompressed &&
            compressedResult.map((fileObject, index) => (
              <Box key={index} mb={4}>
                <ComprssedComponents
                  filename={fileObject.file.filename}
                  originalfilesize={fileObject.file.originalFileSize}
                  compressedfilesize={fileObject.file.compressedFileSize}
                  finalUrl={fileObject.file.url}
                  isFileSizeExceed={fileObject.file.isFileSizeExceed}
                />
              </Box>
            ))}
        </Box>
        <Box p="1rem 0px" mt="2%">
          <AppStoreWeb />
        </Box>
      </Box>

      <Footer />
    </>
  );
}

export default FileUploadingPage;
