import React, { useState } from "react";
import Header from "../components/header";
import FrameComponent from "../components/VoiceComponents/FrameComponent";
import Section from "../components/VoiceComponents/Section";
import ResizeVideoButton1 from "../components/VoiceComponents/ResizeVideoButton1";
import ResizeVideoButton from "../components/VoiceComponents/ResizeVideoButton";
import Headerv from "../components/VoiceComponents/Headerv";
import Footer from "../components/VoiceComponents/Footer";
import MyComponent from "./Section";
// import cardSlider from "../components/VoiceComponents/cardSlider";
import styles from "./VoiceWebsite.module.css";
import style from "./Voiceglobal.css";
import { Box } from "@mui/material";
// import Footer from '../components/footer';
import VoiceAppStore from "../components/VoiceComponents/VoiceAppStore";

// Import PlayButtonComponent from pages directory
import PlayButtonComponent from "./PlayButton";

const VoiceWebsite = () => {
  const [showPlayButton, setShowPlayButton] = useState(false);

  const togglePlayButton = () => {
    setShowPlayButton(!showPlayButton);
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          width:"100%",
          mt: "4rem",
          backgroundColor:"#FFABAF"
        }}
      >
        <VoiceAppStore />
      </Box>
      <div className={styles.voiceWebsite}>
        {!showPlayButton ? (
          <FrameComponent togglePlayButton={togglePlayButton} />
        ) : (
          <PlayButtonComponent />
        )}
        <main className={styles.headerFrame}>
          <Section />
          <MyComponent />
          <cardSlider />
          <section className={styles.videoMakerFlipAdjustFrame}>
            <div className={styles.fRAMEFrame}>
              <div className={styles.ourFeatures}>Our Features</div>
            </div>
            <ResizeVideoButton1 />
            <ResizeVideoButton />
            <div className={styles.fRAMEGroup}>
              <img
                className={styles.fRAMEGroupChild}
                loading="lazy"
                alt=""
                src="../images/group-3135@2x.png"
              />
              <Headerv
                playback="Cloud Storage Integration"
                propPadding="0rem 0rem var(--padding-20xl)"
                propFlexDirection="column"
                propMinWidth="20.938rem"
                propPadding1="0rem 0rem 0rem var(--padding-4xs)"
              />
            </div>

            {/* <AppStore /> */}
          </section>
        </main>

        <Footer />
      </div>
      {/* {showPlayButton && <PlayButtonComponent />} */}
      <cardSlider />
    </>
  );
};

export default VoiceWebsite;
