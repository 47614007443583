import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "../components/header";
import Footer from "../components/footer";
import WelcomeComponents from "../components/HomeComponents/WelcomeComponents";
import { motion } from "framer-motion";
import CardSwiper from "../components/HomeComponents/CardSwiper";
import SwiperSlider from "../components/HomeComponents/SwiperSlider";
import HowToCompressComponent from "../components/HomeComponents/HowToCompressComponent";
import VoiceRecorderComponents from "../components/HomeComponents/VoiceRecorderComponents";
import TapeRecorderComponents from "../components/HomeComponents/TapeRecorderComponents";
import WhyChooseUsComponents from "../components/HomeComponents/WhyChooseUsComponents";
import YoutubeVideoComponent from "../components/HomeComponents/YoutubeVideoComponent";
import AppStore from "../components/StyledComponents/AppStore";

function Home() {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  return (
    <Box>
      <Box
        component={motion.div}
        width={"100%"}
        // height={isNonMobileScreens ? "100vh" : "100%"}
        sx={{
          background: "linear-gradient(90deg, #000717, #002d97)",
        }}
      >
        <Header />
        <WelcomeComponents />
      </Box>
      <Box width={"100%"} height={300}>
        <Box sx={{ position: "relative" }}>
          <SwiperSlider />
        </Box>
      </Box>
      <Box>
        <HowToCompressComponent />
      </Box>
      <Box width={"100%"} sx={{ padding: "1rem 5%" }}>
        <Box
          sx={{
            width: "100%",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
            borderRadius: "25px",
            //  background:"linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 255, 0, 1))"
          }}
        >
          <CardSwiper />
        </Box>
      </Box>
      <Box>
        <VoiceRecorderComponents />
      </Box>
      <Box>
        <TapeRecorderComponents />
      </Box>
      <Box>
        <WhyChooseUsComponents />
      </Box>
      <Box>
        <YoutubeVideoComponent />
      </Box>
      {/* <Box>
         <AppStore />
      </Box> */}
      <Footer />
    </Box>
  );
}

export default Home;
