
// import React, { useState, useCallback } from "react";

// import styles from "./Editbutton.module.css";
// import style from "./Editbuttonglobal.css";
// import Speech from "../components/VoiceComponents/Speech";

// const Editbutton = (props) => {
//   const [speechClicked, setSpeechClicked] = useState(false); // State to track if Speech button is clicked

//   const handleSpeechClick = () => {
//     console.log("Speech button clicked");
//     setSpeechClicked(true); // Set state to true when Speech button is clicked
//   };

//   const audio = new Audio(props?.blobAudio);

//   const onGroupContainerClick = useCallback(() => {
//     // Please sync "Voice Website" to the project
//   }, []);

//   const onGroupIconClick = useCallback(() => {
//     // Please sync "Voice Website" to the project
//   }, []);

//   const handleDeleteClick = useCallback(() => {
//     const confirmed = window.confirm(
//       "Are you sure you want to delete this recording?"
//     );
//     if (confirmed) {
//       // Perform delete action here
//       console.log("Recording deleted!"); // Placeholder for actual delete logic
//     }
//   }, []);

//   const handleDownloadClick = useCallback(() => {
//     const downloadLink = document.createElement("a");
//     downloadLink.href = props?.blobAudio;
//     downloadLink.download = "recorded_audio.mp3"; // Set the desired filename and extension
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
//     document.body.removeChild(downloadLink);
//   }, [props?.blobAudio]);

//   return (
//     <>
//       {speechClicked ? (
//         <Speech speechClicked={speechClicked} />
//       ) : (
//         <div className={styles.voiceWebsite}>
//           <section className={styles.parent}>
//             <img
//               className={styles.icon}
//               alt=""
//               src="../images/12066098-4873152-1@2x.png"
//             />
//             <header className={styles.rectangleParent}>
//               <div className={styles.frameChild} />
//               <img
//                 className={styles.frameItem}
//                 loading="lazy"
//                 alt=""
//                 src="../images/frequency1.jpg"
//               />
//               <audio src={props?.blobAudio} controls />
//             </header>
//             <div className={styles.ellipseCluster} />
//             <div className={styles.ellipseCluster1} />
//             <div className={styles.ellipseCluster2} />
//             <div className={styles.ellipseCluster3} />
//             <div className={styles.ellipseCluster4} />
//             <div className={styles.ellipseCluster5} />
//             <div
//               className={styles.rectangleGroup}
//               onClick={onGroupContainerClick}
//             >
//               <div className={styles.frameInner} />
//               <img
//                 className={styles.editTextIcon}
//                 loading="lazy"
//                 alt=""
//                 src="../images/pause3.svg"
//                 onClick={() => {
//                   audio.play();
//                 }}
//               />
//             </div>
//             <img
//               className={styles.groupIcon}
//               loading="lazy"
//               alt=""
//               src="../images/group-3117.svg"
//               onClick={handleDeleteClick}
//             />
//             <div
//               className={styles.rectangleContainer}
//               onClick={handleDownloadClick}
//             >
//               <div className={styles.rectangleDiv} />
//               <img
//                 className={styles.vectorIcon}
//                 loading="lazy"
//                 alt=""
//                 src="../images/download1.svg"
//               />
//             </div>
//           </section>
//           <h1 className={styles.editYourRecording}>Edit Your Recording</h1>
//           <div className={styles.voiceWebsiteInner}>
//             <div className={styles.frameParent}>
//               <div className={styles.wrapper}>
//                 <div className={styles.div}>00:08 / 05:28</div>
//               </div>
//               <div className={styles.pauseParent}>
//                 <div className={styles.pause}>Listen</div>
//                 <div className={styles.delete}>Delete</div>
//                 <div className={styles.save}>Save</div>
//                 <div className={styles.SpeechButton} onClick={handleSpeechClick}>
//                   Speech
//                 </div>

//                 {/* {speechClicked && <Speech />} */}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Editbutton;

// end ajeeth
import React, { useRef, useEffect, useState } from 'react';
// import WaveSurfer from 'wavesurfer.js';
// import './Editbutton.css';
import FrameComponent from '../components/VoiceComponents/FrameComponent';

const EditButton = ({ blobAudio }) => {
//   const waveformRef = useRef(null);
//   const wavesurfer = useRef(null);
//   const [isDeleted, setIsDeleted] = useState(false);
//   const [navigateToRecord, setNavigateToRecord] = useState(false);

//   useEffect(() => {
//     if (!WaveSurfer) {
//       console.error('WaveSurfer is not available');
//       return;
//     }

//     wavesurfer.current = WaveSurfer.create({
//       container: waveformRef.current,
//       waveColor: 'violet',
//       progressColor: 'purple',
//       cursorColor: 'navy',
//       height: 128
//     });

//     if (blobAudio) {
//       wavesurfer.current.load(blobAudio);
//     }

//     wavesurfer.current.on('ready', () => {
//       console.log('WaveSurfer is ready');
//     });

//     return () => {
//       if (wavesurfer.current) {
//         wavesurfer.current.destroy();
//       }
//     };
//   }, [blobAudio]);

//   const handlePlayPause = () => {
//     if (wavesurfer.current) {
//       wavesurfer.current.playPause();
//     }
//   };

//   const handleDelete = () => {
//     if (window.confirm("Are you sure you want to delete the audio file?")) {
//       URL.revokeObjectURL(blobAudio);
//       if (wavesurfer.current) {
//           wavesurfer.current.destroy();
//       }
//       setIsDeleted(true);
//     }
//   };

//   const handleDownload = () => {
//     const anchor = document.createElement('a');
//     anchor.style = 'display: none';
//     anchor.href = blobAudio;
//     anchor.download = 'recording.wav';
//     document.body.appendChild(anchor);
//     anchor.click();
//     window.URL.revokeObjectURL(anchor.href);
//     document.body.removeChild(anchor);
//   };
// const handleNavigateToRecord = () => {
//     setNavigateToRecord(true);
//   };

//   if (isDeleted || navigateToRecord) {
//     return <FrameComponent shouldStartRecording={navigateToRecord} />;
//   }

  return (
    // <div className="editor">
    //   <div id="waveform" ref={waveformRef} />
    //   <div className="controls">
    //     <button onClick={handlePlayPause}>Play/Pause</button>
    //     <button onClick={handleDownload}>Download</button>
    //     <button onClick={handleDelete}>Delete</button>
    //     <button onClick={handleNavigateToRecord}>Record New</button>
    //   </div>
    // </div>
    <></>
  );
};

export default EditButton;

