import React from "react";
import { Box, useMediaQuery, Typography, useTheme, Link } from "@mui/material";
import FlexBetween from "../StyledComponents/FlexBetween";
import FlexCenter from "../StyledComponents/FlexCenter";

function HomeAppStore() {
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();

  return (
    <FlexCenter>
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "flex", lg: "flex" },
          justifyContent: "flex-start",
          gap: { xs: "0rem", sm: "0rem", md: "2.375rem", lg: "2.375rem" },
        }}
      >
        <FlexCenter>
          <Typography
            sx={{
              color: "black",
              fontSize: "24px",
              fontWeight: "800",
              wordWrap: "break-word",
              textAlign: "center",
              mt: { xs: "1rem", sm: "1rem", md: "0px", lg: "0px" },
            }}
            variant={theme.typography.h3}
          >
            Get it on Mobile
          </Typography>
        </FlexCenter>
        <FlexCenter p={isNonMobile ? "0px" : "0.266rem 3%"}>
          <Typography
            sx={{
              color: "black",
              fontSize: "20px",
              fontWeight: "500",
              wordWrap: "break-word",
              textAlign: "center",
              fontFamily: '"Poppins", "Rubik", sans-serif', // Use Poppins as the primary font
            }}
            // variant={theme.typography.fontFamily}
          >
            Compress Videos directly on Mobile Device
            {/* using our{" "} */}
            {/* <Link
            href="https://play.google.com/store/apps/details?id=com.raytechnoto.video.compressor.converter&hl=en"
            // sx={{ color: "#727CF5", textDecoration: "none" }}
            sx={{ color: "#fcf9f9", textDecoration: "none" }}
          >
            Android Video Compressor
          </Link>{" "}
          or
          <Link
            href="https://apps.apple.com/us/app/video-compressor-file-resizer/id6475106894"
            // sx={{ color: "#727CF5", textDecoration: "none" }}
            sx={{ color: "#fcf9f9", textDecoration: "none" }}
          >
            {" "}
            iOS Video Compressor
          </Link> */}
          </Typography>
        </FlexCenter>
        <Box
          padding={{
            xs: "0px  0px 2rem 0px",
            sm: "0px  0px 2rem 0px",
            md: "1rem 0px",
            lg: "1rem 0px",
          }}
        >
          <FlexCenter>
            <FlexBetween
              gap={"2rem"}
              display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex" }}
            >
              <Link
                href="https://play.google.com/store/apps/details?id=com.raytechnoto.video.compressor.converter&hl=en&g%20l=us"
                sx={{ textDecoration: "none" }}
              >
                <Box
                  bgcolor="black"
                  //   bgcolor="white"
                  minWidth="80px"
                  minHeight="20px"
                  p="0.25rem 0.5rem"
                  borderRadius="6px"
                  // boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                  mt={isNonMobile ? "0px" : "10px"}
                >
                  <img
                    src="../assets/Google-play.svg"
                    // src="../assets/google.svg"
                    alt=""
                    style={{
                      //   color: "black",
                      height: "40px",
                      width: "133px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Link>
              <Link href="https://apps.apple.com/app/apple-store/id6475106894?pt=125306942&ct=VideoCompressorWeb&mt=8">
                <Box
                  bgcolor="black"
                  //   bgcolor="white"
                  minWidth="80px"
                  minHeight="20px"
                  p="0.25rem 0.5rem"
                  borderRadius="6px"
                  // boxShadow="0px 2px 8px 1px rgba(0, 0, 0, 0.50)"
                  mt={isNonMobile ? "0px" : "10px"}
                >
                  <img
                    src="../assets/apple.svg"
                    // src="../assets/apple.svg"
                    alt=""
                    style={{
                      //   color: "black",
                      height: "40px",
                      width: "133px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Link>
            </FlexBetween>
          </FlexCenter>
        </Box>
      </Box>
    </FlexCenter>
  );
}

export default HomeAppStore;
